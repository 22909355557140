import React from 'react';

function imageSection() {
  return (
    <div className="imagesElements">
     <img class="imagesElements__celular" src="https://imgur.com/oPyFmps.jpg" alt="cellphone" />
    <img class="imagesElements__laptop"  src="https://imgur.com/N1ndGNT.jpg" alt="laptop" />

    </div>
  );
}

export default imageSection;


